<template>
  <div>
    <h2>{{ $t('components.daily_sudoku.h2_conditions') }}</h2>
    <ol>
      <li>
        <i18n path="components.daily_sudoku.conditions.li_condition_1">
          <template #title>{{ $t('globals.title') }}</template>
        </i18n>
      </li>
      <li>
        <i18n path="components.daily_sudoku.conditions.li_condition_2">
          <template #title>{{ $t('globals.title') }}</template>
        </i18n>
      </li>
      <li>
        <i18n path="components.daily_sudoku.conditions.li_condition_3">
          <template #title>{{ $t('globals.title') }}</template>
        </i18n>
      </li>
      <li>
        <i18n path="components.daily_sudoku.conditions.li_condition_4">
          <template #title>{{ $t('globals.title') }}</template>
        </i18n>
      </li>
      <li>
        <i18n path="components.daily_sudoku.conditions.li_condition_5">
          <template #terms>
            <hyperlink :href="$t('hyperlinks.terms.href')" :aria-label="$t('hyperlinks.terms.purpose')">{{
              $t('components.daily_sudoku.conditions.a_terms')
            }}</hyperlink>
          </template>
        </i18n>
      </li>
      <li>
        <i18n path="components.daily_sudoku.conditions.li_condition_6">
          <template #supporterMembership>
            <hyperlink :href="$t('hyperlinks.supporter.href')" :aria-label="$t('hyperlinks.supporter.purpose')">{{
              $t('components.daily_sudoku.conditions.a_supporter')
            }}</hyperlink>
          </template>
          <template #title>{{ $t('globals.title') }}</template>
        </i18n>
      </li>
      <li>
        <i18n path="components.daily_sudoku.conditions.li_condition_7_1">
          <template #title>{{ $t('globals.title') }}</template>
        </i18n>
        <Address class="my-3" />
        {{ $t('components.daily_sudoku.conditions.li_condition_7_2') }}
      </li>
      <li>
        <i18n path="components.daily_sudoku.conditions.li_condition_8">
          <template #company>{{ legal.firstName }} {{ legal.lastName }} - {{ legal.company }}</template>
        </i18n>
      </li>
      <li>
        <i18n path="components.daily_sudoku.conditions.li_condition_9">
          <template #title>{{ $t('globals.title') }}</template>
        </i18n>
      </li>
      <li>{{ $t('components.daily_sudoku.conditions.li_condition_10') }}</li>
    </ol>
    <v-row class="my-0">
      <v-col class="pl-8">
        <v-checkbox
          v-model="termsAccepted"
          :label="$t('components.app.daily_sudoku.checkbox_accept_terms')"
          :value="true"
          color="success"
          :disabled="!isAuthenticated"
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-alert v-if="!isAuthenticated" dense text color="info" class="cursor-pointer" @click="scrollToTop()">
      <template #prepend>
        <fa-icon :icon="['fal', 'circle-info']" size="xl" class="ma-2 mr-4" />
      </template>
      <p class="mb-0">{{ $t('components.daily_sudoku.conditions.p_alert') }}</p>
      <template #append>
        <fa-icon :icon="['fas', 'chevron-up']" class="blink ma-2 ml-4" />
      </template>
    </v-alert>

    <v-alert v-else-if="termsAccepted" dense text color="success">
      <template #prepend>
        <fa-icon :icon="['fal', 'circle-info']" size="xl" class="ma-2 mr-4" />
      </template>
      <p class="mb-0">{{ $t('components.daily_sudoku.alert_confirmed') }}</p>
    </v-alert>
    <v-alert v-else dense text color="info">
      <template #prepend>
        <fa-icon :icon="['fal', 'circle-info']" size="xl" class="ma-2 mr-4" />
      </template>
      <p class="mb-0">{{ $t('components.daily_sudoku.alert_declined') }}</p>
    </v-alert>
  </div>
</template>

<script>
import Address from '@/components/Address.vue'
import Hyperlink from '@/components/Hyperlink.vue'
import { Com } from '@/util'
import { legal } from '@/config/index.js'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Conditions',
  components: { Address, Hyperlink },
  computed: {
    ...mapGetters(['profile', 'user']),
    isAuthenticated() {
      return !!this.user.authentication.exp
    },
    termsAccepted: {
      get() {
        return this.profile.userDailySudokuAgreement === true ? true : null
      },
      async set(val) {
        const agreement = !!val
        const com = new Com()
        await com.updateDailySudokuAgreement(agreement)
        this.setProfile({ userDailySudokuAgreement: agreement })
      },
    },
    legal() {
      return legal
    },
  },
  methods: {
    ...mapActions(['setProfile']),
    scrollToTop() {
      this.$vuetify.goTo(0)
    },
  },
}
</script>

<style lang="scss"></style>
