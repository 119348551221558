<template>
  <svg
    class="amazon__svg"
    :width="width"
    viewBox="0 0 670 215"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xml:space="preserve"
    xmlns:serif="http://www.serif.com/"
  >
    <g id="path8" transform="matrix(1,0,0,1,10.5,9.75051)">
      <path
        class="amazon__svg-path"
        d="M402.462,152.886C364.763,180.674 310.121,195.499 263.074,195.499C197.108,195.499 137.722,171.101 92.793,130.521C89.263,127.33 92.426,122.981 96.662,125.466C145.148,153.677 205.1,170.649 267.028,170.649C308.793,170.649 354.738,162.008 396.984,144.076C403.366,141.365 408.703,148.255 402.462,152.886"
      />
    </g>
    <g id="path10" transform="matrix(1,0,0,1,10.5,9.75051)">
      <path
        class="amazon__svg-path"
        d="M418.135,134.955C413.334,128.798 386.281,132.046 374.138,133.486C370.439,133.938 369.874,130.719 373.207,128.403C394.753,113.239 430.108,117.616 434.231,122.699C438.354,127.81 433.158,163.25 412.911,180.165C409.804,182.763 406.839,181.379 408.223,177.934C412.769,166.582 422.964,141.139 418.135,134.955"
      />
    </g>
    <g id="path12" transform="matrix(1,0,0,1,10.5,9.75051)">
      <path
        class="amazon__svg-path"
        d="M374.986,21.349L374.986,6.608C374.986,4.377 376.68,2.881 378.713,2.881L444.708,2.881C446.826,2.881 448.52,4.406 448.52,6.608L448.52,19.231C448.492,21.349 446.713,24.117 443.55,28.494L409.352,77.319C422.06,77.008 435.474,78.9 446.995,85.395C449.593,86.864 450.299,89.01 450.497,91.128L450.497,106.857C450.497,109.003 448.125,111.516 445.64,110.217C425.336,99.571 398.367,98.413 375.917,110.33C373.63,111.573 371.23,109.088 371.23,106.942L371.23,92.003C371.23,89.603 371.258,85.508 373.658,81.865L413.278,25.048L378.798,25.048C376.68,25.048 374.986,23.552 374.986,21.349"
      />
    </g>
    <g id="path14" transform="matrix(1,0,0,1,10.5,9.75051)">
      <path
        class="amazon__svg-path"
        d="M134.248,113.324L114.17,113.324C112.25,113.182 110.725,111.742 110.584,109.907L110.584,6.863C110.584,4.801 112.306,3.163 114.453,3.163L133.175,3.163C135.124,3.248 136.677,4.745 136.818,6.608L136.818,20.078L137.185,20.078C142.07,7.06 151.248,0.989 163.617,0.989C176.183,0.989 184.034,7.06 189.681,20.078C194.538,7.06 205.58,0.989 217.412,0.989C225.827,0.989 235.033,4.462 240.653,12.256C247.006,20.926 245.707,33.52 245.707,44.562L245.679,109.596C245.679,111.657 243.957,113.324 241.811,113.324L221.761,113.324C219.756,113.182 218.146,111.573 218.146,109.596L218.146,54.982C218.146,50.633 218.542,39.789 217.581,35.666C216.085,28.748 211.595,26.799 205.778,26.799C200.92,26.799 195.837,30.047 193.776,35.243C191.715,40.439 191.912,49.136 191.912,54.982L191.912,109.596C191.912,111.657 190.19,113.324 188.043,113.324L167.994,113.324C165.961,113.182 164.379,111.573 164.379,109.596L164.351,54.982C164.351,43.488 166.243,26.573 151.982,26.573C137.552,26.573 138.117,43.065 138.117,54.982L138.117,109.596C138.117,111.657 136.394,113.324 134.248,113.324"
      />
    </g>
    <g id="path16" transform="matrix(1,0,0,1,10.5,9.75051)">
      <path
        class="amazon__svg-path"
        d="M505.337,0.989C535.129,0.989 551.253,26.573 551.253,59.105C551.253,90.535 533.435,115.47 505.337,115.47C476.081,115.47 460.154,89.885 460.154,58.003C460.154,25.924 476.279,0.989 505.337,0.989M505.506,22.027C490.709,22.027 489.777,42.189 489.777,54.756C489.777,67.35 489.579,94.234 505.337,94.234C520.896,94.234 521.631,72.546 521.631,59.331C521.631,50.633 521.264,40.241 518.637,31.995C516.378,24.823 511.888,22.027 505.506,22.027"
      />
    </g>
    <g id="path18" transform="matrix(1,0,0,1,10.5,9.75051)">
      <path
        class="amazon__svg-path"
        d="M589.884,113.324L569.891,113.324C567.886,113.182 566.277,111.573 566.277,109.596L566.248,6.524C566.418,4.632 568.084,3.163 570.117,3.163L588.727,3.163C590.477,3.248 591.918,4.434 592.313,6.044L592.313,21.801L592.68,21.801C598.3,7.71 606.178,0.989 620.044,0.989C629.052,0.989 637.834,4.236 643.482,13.132C648.734,21.377 648.734,35.243 648.734,45.211L648.734,110.076C648.508,111.883 646.842,113.324 644.866,113.324L624.731,113.324C622.896,113.182 621.371,111.827 621.173,110.076L621.173,54.106C621.173,42.839 622.472,26.347 608.607,26.347C603.721,26.347 599.231,29.623 597.001,34.593C594.177,40.891 593.81,47.16 593.81,54.106L593.81,109.596C593.781,111.657 592.031,113.324 589.884,113.324"
      />
    </g>
    <g id="path28" transform="matrix(1,0,0,1,10.5,9.75051)">
      <path
        class="amazon__svg-path"
        d="M322.376,64.103C322.376,71.925 322.574,78.448 318.621,85.395C315.43,91.043 310.347,94.516 304.727,94.516C297.018,94.516 292.499,88.643 292.499,79.973C292.499,62.86 307.833,59.754 322.376,59.754L322.376,64.103M342.624,113.041C341.296,114.227 339.376,114.312 337.88,113.521C331.215,107.986 330.001,105.417 326.358,100.136C315.345,111.375 307.523,114.735 293.262,114.735C276.347,114.735 263.216,104.315 263.216,83.447C263.216,67.153 272.026,56.055 284.621,50.633C295.521,45.832 310.742,44.985 322.376,43.658L322.376,41.06C322.376,36.288 322.743,30.64 319.92,26.517C317.491,22.818 312.803,21.293 308.652,21.293C300.999,21.293 294.194,25.218 292.528,33.351C292.189,35.158 290.862,36.937 289.026,37.022L269.569,34.932C267.932,34.565 266.096,33.238 266.576,30.724C271.038,7.117 292.387,0 311.476,0C321.247,0 334.011,2.598 341.72,9.997C351.491,19.118 350.559,31.289 350.559,44.533L350.559,75.822C350.559,85.226 354.456,89.349 358.127,94.432C359.398,96.239 359.68,98.413 358.042,99.769C353.947,103.186 346.662,109.54 342.652,113.098L342.624,113.041"
      />
    </g>
    <g id="path30" transform="matrix(1,0,0,1,10.5,9.75051)">
      <path
        class="amazon__svg-path"
        d="M59.161,64.103C59.161,71.925 59.358,78.448 55.405,85.395C52.214,91.043 47.159,94.516 41.511,94.516C33.802,94.516 29.312,88.643 29.312,79.973C29.312,62.86 44.646,59.754 59.161,59.754L59.161,64.103M79.408,113.041C78.081,114.227 76.161,114.312 74.664,113.521C67.999,107.986 66.813,105.417 63.142,100.136C52.129,111.375 44.335,114.735 30.046,114.735C13.159,114.735 -0,104.315 -0,83.447C-0,67.153 8.839,56.055 21.405,50.633C32.305,45.832 47.526,44.985 59.161,43.658L59.161,41.06C59.161,36.288 59.528,30.64 56.732,26.517C54.275,22.818 49.588,21.293 45.465,21.293C37.812,21.293 30.978,25.218 29.312,33.351C28.973,35.158 27.646,36.937 25.839,37.022L6.354,34.932C4.716,34.565 2.909,33.238 3.36,30.724C7.85,7.117 29.171,0 48.26,0C58.031,0 70.795,2.598 78.504,9.997C88.275,19.118 87.343,31.289 87.343,44.533L87.343,75.822C87.343,85.226 91.24,89.349 94.911,94.432C96.21,96.239 96.493,98.413 94.855,99.769C90.76,103.186 83.474,109.54 79.465,113.098L79.408,113.041"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Amazon',
  data: () => ({
    width: '70%',
  }),
}
</script>

<style lang="scss">
.amazon {
  &__svg {
    fill-rule: evenodd;
    clip-rule: evenodd;
    stroke-linejoin: round;
    stroke-miterlimit: 2;
  }
}

.amazon {
  &__svg-path {
    fill: #fff;
  }
}
</style>
