<template>
  <div>
    <p class="font-weight-bold mb-0">{{ legal.firstName }} {{ legal.lastName }}</p>
    <p class="font-italic mb-0">{{ legal.company }}</p>
    <p>
      {{ legal.streetAddress }}<br />
      {{ legal.postalCode }} {{ legal.city }}<br />
      {{ legal.country }}
    </p>
  </div>
</template>

<script>
import { legal } from '@/config/index.js'

export default {
  name: 'Address',
  computed: {
    legal() {
      return legal
    },
  },
}
</script>

<style lang="scss"></style>
