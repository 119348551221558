<template>
  <div>
    <h1 class="d-print-none">{{ $t('views.daily_sudoku.h1_title') }}</h1>
    <p class="d-print-none" :class="profile.userDailySudokuAgreement ? 'mb-sm-12' : ''">
      <i18n path="views.daily_sudoku.p_about">
        <template #here>
          <hyperlink :href="$t('hyperlinks.winners.href')" :aria-label="$t('hyperlinks.winners.purpose')">{{
            $t('views.daily_sudoku.a_here')
          }}</hyperlink>
        </template>
      </i18n>
    </p>
    <v-alert
      v-if="!profile.userDailySudokuAgreement"
      dense
      text
      color="info"
      class="d-print-none mb-6 cursor-pointer"
      @click="scrollToBottom()"
    >
      <template #prepend>
        <fa-icon :icon="['fal', 'circle-info']" size="xl" class="ma-2 mr-4" />
      </template>
      <p class="mb-0">{{ $t('views.daily_sudoku.p_alert') }}</p>
      <template #append>
        <fa-icon :icon="['fas', 'chevron-down']" class="blink ma-2 ml-4" />
      </template>
    </v-alert>
    <div class="text-center my-3">
      <Adslot ad-unit="content-first" ad-class="adslot__content-first" />
    </div>
    <h2 class="text-center">{{ currentDate }}</h2>
    <v-divider class="d-print-none mx-6 mx-sm-0 mb-3"></v-divider>
    <div class="d-print-none text-center mb-13">
      <countdown :remaining-seconds="sudoku.remainingSeconds" @countdownExpired="setCountdownExpired"></countdown>
    </div>
    <sudoku :daily-sudoku="true" :class="{ 'daily-sudoku--disabled': !profile.userDailySudokuAgreement || countdownExpired }"></sudoku>
    <div class="d-print-none">
      <div class="text-center pt-3 mx-auto mb-12" style="width: calc(100vw - 48px); max-width: 400px">
        <v-btn v-if="countdownExpired" rounded outlined color="$theme.btn.color" @click="reload()">
          <fa-icon class="mr-2" size="lg" :icon="['fal', 'redo']" />{{ $t('views.daily_sudoku.btn_reload') }}
        </v-btn>
        <v-btn
          v-else-if="!isSolved"
          :disabled="!profile.userDailySudokuAgreement"
          rounded
          outlined
          color="$theme.btn.color"
          @click="checkDailySudoku()"
        >
          <fa-icon class="mr-2" size="lg" :icon="['fad', 'tasks']" />{{ $t('views.home.btn_check_sudoku') }}
        </v-btn>
        <btn-home v-if="isSolved && !countdownExpired"></btn-home>
      </div>
    </div>
    <v-dialog v-model="dialogCongratulations" persistent max-width="520">
      <v-card>
        <v-card-title class="headline pt-6 text-center" style="display: block">
          {{ $t('views.daily_sudoku.headline_congratulations') }}

          <v-btn fab text style="position: absolute; top: 10px; right: 8px" @click="dialogCongratulations = false">
            <fa-icon :class="$theme.app.navigation.buttons.open.text" size="2xl" :icon="['fal', 'times']"></fa-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="text-center">
          <div class="pt-3 mb-3">
            <fa-icon class="green--text" size="5x" :icon="['fas', 'check-circle']" />
          </div>
          <p>
            <i18n path="views.daily_sudoku.p_solved">
              <template #currentDate>
                {{ currentDate }}
              </template>
            </i18n>
          </p>
          <div v-if="!isSupporter && $vuetify.breakpoint.mdAndUp">
            <Adslot ad-unit="solved-rectangle" ad-class="adslot__modal-solved" class="mx-auto" />
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-divider class="d-print-none my-6"></v-divider>
    <div class="d-print-none">
      <h2>
        <i18n path="views.daily_sudoku.h2_prizes">
          <template #year>{{ currentYear() }}</template>
          <template #month>{{ currentMonth() }}</template>
        </i18n>
      </h2>
      <v-row class="my-3">
        <v-col v-for="(voucher, index) in vouchers" :key="index" cols="12" sm="6">
          <v-sheet :color="voucher.backgroundColor" class="rounded-lg mx-9 mx-md-3 px-6 px-sm-3 px-lg-6" elevation="2">
            <div :class="voucher.textColor" class="text-center pt-6 pb-3">
              <amazon class="daily-sudoku--amazon" />
              <p class="text-h7 mb-0 font-weight-black">{{ voucher.placement }}</p>
              <p class="mb-0">{{ voucher.text }}</p>
              <p class="text-h5 mb-0 font-weight-black">{{ voucher.value }}<sup>*</sup></p>
            </div>
          </v-sheet>
        </v-col>
      </v-row>
      <div class="text-center my-3">
        <Adslot ad-unit="content-second" ad-class="adslot__content-second" />
      </div>
      <p class="text-caption">
        *
        <i18n path="views.daily_sudoku.p_ad_free">
          <template #supporterMembership>
            <hyperlink :href="$t('hyperlinks.supporter.href')" :aria-label="$t('hyperlinks.supporter.purpose')">{{
              $t('views.daily_sudoku.a_supporter_membership')
            }}</hyperlink>
          </template>
        </i18n>
      </p>
      <Conditions />
    </div>
    <div class="text-center my-3">
      <Adslot ad-unit="content-last" ad-class="adslot__content-last" />
    </div>
  </div>
</template>

<script>
import Adslot from '@/components/Adslot.vue'
import Amazon from '@/components/svg/Amazon.vue'
import BtnHome from '@/components/btn/Home'
import Conditions from '@/components/daily-sudoku/Conditions.vue'
import Countdown from '@/components/daily-sudoku/Countdown'
import Hyperlink from '@/components/Hyperlink.vue'
import Sudoku from '@/components/Sudoku'
import { Com } from '@/util'
import { mapActions, mapGetters } from 'vuex'
import { settings } from '@/config'
import { theme } from '@/mixins'

export default {
  name: 'DailySudoku',
  components: {
    Adslot,
    Amazon,
    BtnHome,
    Conditions,
    Countdown,
    Hyperlink,
    Sudoku,
  },
  mixins: [theme],
  data() {
    return {
      dialogCongratulations: false,
      countdownExpired: false,
    }
  },
  computed: {
    ...mapGetters(['profile', 'progress', 'isSupporter']),
    sudoku() {
      return this.$store.getters.sudoku
    },
    isSolved() {
      return this.sudoku.solved
    },
    currentDate() {
      const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', timeZone: settings.timeZone }
      const dailyPuzzleDate = new Date()
      return dailyPuzzleDate.toLocaleDateString(this.$i18n.locale, options)
    },
    vouchers() {
      return [
        {
          backgroundColor: this.$theme.views.dailySudoku.vouchers[0].backgroundColor,
          textColor: this.$theme.views.dailySudoku.vouchers[0].textColor,
          placement: this.$t('views.daily_sudoku.vouchers.p_placement_1'),
          text: this.$t('views.daily_sudoku.vouchers.p_text'),
          value: '50 EUR',
        },
        {
          backgroundColor: this.$theme.views.dailySudoku.vouchers[1].backgroundColor,
          textColor: this.$theme.views.dailySudoku.vouchers[1].textColor,
          placement: this.$t('views.daily_sudoku.vouchers.p_placement_2'),
          text: this.$t('views.daily_sudoku.vouchers.p_text'),
          value: '10 EUR',
        },
      ]
    },
  },
  async created() {
    const com = new Com()
    const res = await com.getDailySudoku(3)
    await this.$store.dispatch('initSudoku', res.data)
    if (typeof res.data.savegame !== 'undefined') {
      await this.$store.dispatch('setSudoku', JSON.parse(res.data.savegame))
    }
  },
  mounted() {
    this.setActiveElementId(1)
  },
  methods: {
    ...mapActions(['setSavingActive', 'setStatistics', 'setActiveElementId']),
    async checkDailySudoku() {
      const solution = this.sudoku.solution.split('')
      let total = this.sudoku.puzzle.split('')
      await this.$store.dispatch('setActiveCellId', null)
      this.sudoku.entries.forEach((entry, index) => {
        if (entry === null) return
        if (typeof entry !== 'undefined' && Number(entry) !== Number(solution[index])) {
          this.$store.dispatch('setWarning', {
            elementId: index,
            val: true,
          })
        }
        total[index] = String(entry)
      })
      await this.$store.dispatch('incrementChecked')
      this.setSavingActive(true)
      if (total.toString() === solution.toString()) await this.$store.dispatch('setSolved')
      const com = new Com()
      const response = await com.updateDailySudokuSavegame(JSON.stringify(this.sudoku), this.progress)
      if (typeof response === 'object') {
        await this.$store.dispatch('resetSavingCounter')
        if (this.sudoku.solved) {
          this.dialogCongratulations = true
          const sudokuStatistics = await com.getSudokuStatistics()
          await this.$store.dispatch('setSudokuStatistics', sudokuStatistics.data)
          const res = await com.getDailySudokuStatistics()
          if (typeof res.data !== 'undefined') await this.setStatistics(res.data)
        }
      }
      setTimeout(() => {
        this.setSavingActive(false)
      }, 1)
    },
    setCountdownExpired(val) {
      this.countdownExpired = val
    },
    currentMonth() {
      const date = new Date()
      return this.$t(`globals.months.month_${date.getMonth() + 1}`)
    },
    currentYear() {
      const date = new Date()
      return date.getFullYear()
    },
    scrollToBottom() {
      this.$vuetify.goTo(document.body.scrollHeight)
    },
    reload() {
      location.reload()
    },
  },
}
</script>

<style lang="scss">
.daily-sudoku {
  &--amazon {
    max-width: 200px !important;
  }
  @media screen {
    &--disabled {
      opacity: 0.25;
      position: relative;
    }
    &--disabled:after {
      position: absolute;
      top: -56px;
      left: 0;
      right: 0;
      bottom: 0;
      content: '';
    }
  }
}
</style>
