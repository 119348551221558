<template>
  <div>
    <v-row class="mx-0 mt-0 mb-3 px-6 px-md-0">
      <v-col cols="12" class="daily-sudoku-countdown text-center pa-0">
        <div class="countdown-text white--text">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <strong class="cursor-pointer" v-bind="attrs" v-on="on">
                <span class="countdown-flap rounded pa-2">{{ countdown[0] }}</span>
                <span class="countdown-flap rounded pa-2 mx-2">{{ countdown[1] }}</span>
                <span class="countdown-flap rounded pa-2">{{ countdown[2] }}</span>
              </strong>
            </template>
            <div class="text-center">
              Countdown bis zum nächsten Rätsel.<br />Serverzeit<fa-icon class="fa-fw mx-1" :icon="['fal', 'globe']"></fa-icon>Europa/Berlin
            </div>
          </v-tooltip>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'Countdown',
  props: {
    remainingSeconds: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      beginCountdown: Math.floor(new Date() / 1000),
      countdown: ['00', '00', '00'],
      showTooltip: false,
      countdownExpired: false,
    }
  },
  computed: {
    countdownSeconds() {
      return parseInt(this.countdown[0]) * 3600 + parseInt(this.countdown[1]) * 60 + parseInt(this.countdown[2])
    },
  },
  watch: {
    countdownSeconds: function () {
      this.countdownExpired = !(this.countdownSeconds > 0)
    },
    countdownExpired: function () {
      this.$emit('countdownExpired', this.countdownExpired)
    },
  },
  mounted() {
    setInterval(() => (this.countdown = this.countdownRunning().split(':')), 10)
  },
  methods: {
    countdownRunning() {
      const currentDatetime = Math.floor(new Date() / 1000)
      const currentRemainingSeconds = this.remainingSeconds - (currentDatetime - this.beginCountdown)
      if (currentRemainingSeconds < 0) {
        return '00:00:00'
      }
      return new Date(currentRemainingSeconds * 1000).toLocaleTimeString(this.$i18n.locale, {
        hour12: false,
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        timeZone: 'UTC',
      })
    },
  },
}
</script>

<style lang="scss">
@import 'src/styles/variables.scss';
.daily-sudoku-countdown {
  position: relative;
  z-index: 2;
}
.countdown-text {
  font-family: $countdown-font-family;
}
.countdown-flap {
  background-color: map-get($grey, 'darken-3');
  background: linear-gradient(0deg, map-get($grey, 'darken-3') 50%, map-get($grey, 'darken-2') 50%);
}
</style>
